/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetStockListQuery,
  Product,
  useGetProductWithIdLazyQuery,
  useGetStockListLazyQuery,
  useStockSyncLazyQuery,
} from "../../generated/graphql";
import { formattedDate } from "../../utils/constant";
import IconifyIcon from "../icon/Icon";
import StockTable from "./StockTable";
import { useReactToPrint } from "react-to-print";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

export function OneItemStock() {
  const { itemCode } = useParams();
  const [stock, setStock] = useState<GetStockListQuery | undefined>(undefined);

  const [item, setItem] = useState<Product | undefined>(undefined);
  const [fetchStock] = useGetStockListLazyQuery();
  const [fetchItem] = useGetProductWithIdLazyQuery();

  const [makeStockSync, { loading: makeStockLoading }] = useStockSyncLazyQuery(
    {},
  );

  useEffect(() => {
    fetchItemData();
  }, [itemCode]);

  const fetchItemData = async () => {
    const [{ data }, { data: item }] = await Promise.all([
      fetchStock({
        variables: {
          itemCode: String(itemCode),
        },
        fetchPolicy: "no-cache",
      }),
      fetchItem({
        variables: {
          id: String(itemCode),
        },
      }),
    ]);

    setStock(data);

    setItem(item?.getProductWithId);
  };

  const componentRef = useRef<React.ReactElement | null>();

  const handlePrint = useReactToPrint({
    content: () => {
      const contentToPrint = (componentRef.current as any).cloneNode(true); // Clone the element

      const elementsToRemove =
        contentToPrint.querySelectorAll(".not-printable"); // Select elements to remove
      elementsToRemove.forEach(
        (element: { parentNode: { removeChild: (arg0: any) => any } }) =>
          element.parentNode.removeChild(element),
      );
      return contentToPrint!;
    },
  });

  return (
    <div>
      <Grid display="flex" justifyContent={"space-between"}>
        <Grid flexGrow={1}>
          <Breadcrumbs aria-label="breadcrumb">
            <Grid
              onClick={() => window.navigation.back()}
              // component={NavLink}
              // to="/items/stock"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 2,
                cursor: "pointer",
              }}
            >
              <IconifyIcon icon="mdi:arrow-left" />
              <Typography
                sx={{ marginLeft: 1, textDecorationLine: "underline" }}
              >
                Back to stock register
              </Typography>
            </Grid>
          </Breadcrumbs>
        </Grid>

        {item?.id && (
          <>
            <LoadingButton
              loading={makeStockLoading}
              size="small"
              onClick={async () => {
                const { data } = await makeStockSync({
                  variables: {
                    itemCode: item.itemCode,
                  },
                });
                toast.success(data?.stockSync!);
                fetchItemData();
              }}
              variant="contained"
              type="button"
              sx={{ marginRight: 2 }}
            >
              Make Sync Record
            </LoadingButton>
            <Button
              size="small"
              onClick={handlePrint}
              variant="contained"
              type="button"
            >
              Print{" "}
            </Button>
          </>
        )}
      </Grid>
      <Box ref={componentRef}>
        <Box
          display="flex"
          padding={2}
          alignItems={{ xs: "stretch", md: "center" }}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" justifyContent={"flex-start"}>
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection={"column"}
              justifyContent={"flex-start"}
              marginBottom={{ xs: "20px", md: "0px" }}
            >
              <Typography variant="h3" component="h3" gutterBottom>
                {item?.name}
              </Typography>
              <Typography variant="body1"> {item?.itemCode}</Typography>
            </Box>
          </Box>

          <Box
            alignItems="center"
            marginBottom={{ xs: "10px", md: "0px" }}
            marginRight={5}
          >
            <Typography fontWeight={600} variant="body1">
              Date: {formattedDate(new Date())}
            </Typography>
          </Box>
        </Box>

        <Grid paddingTop={"10px"} paddingBottom={"10px"}>
          <Grid overflow={"auto"} item sm={12}>
            <StockTable product={item} items={stock?.getStockList!} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

//  {
//    (() => {
//      return (
//        <div style={{ display: "none" }}>
//          <ComponentToPrint ref={componentRef}>
//            {(() => {
//              if (!data?.getStockList.length) return <></>;

//              return (
//                <div>
//                  <Typography variant="h6" paddingBottom={"20px"}>
//                    Product Stock {item?.name}
//                  </Typography>
//                  <Div>
//                    <Grid item flexGrow={1}>
//                      {/* <Div>
//                             <Typography variant="body1" component="span">
//                               Invoice number
//                             </Typography>
//                             <Typography variant="body1" component="span">
//                               {item?.id}
//                             </Typography>
//                           </Div>
//                           <Div>
//                             <Typography variant="body1" component="span">
//                               Party code
//                             </Typography>
//                             <Typography variant="body1" component="span">
//                               {item?.partyCode}
//                             </Typography>
//                           </Div>
//                           <Div>
//                             <Typography variant="body1" component="span">
//                               Party Name
//                             </Typography>
//                             <Typography variant="body1" component="span">
//                               {party?.partyName}
//                             </Typography>
//                           </Div> */}
//                      <Div>
//                        <Typography variant="body1" component="span">
//                          Mislanious party name
//                        </Typography>
//                        <Typography variant="body1" component="span">
//                          232323
//                        </Typography>
//                      </Div>
//                    </Grid>
//                    <Grid item marginLeft={"30px"} flexGrow={1}>
//                      <Div>
//                        <Typography variant="body1" component="span">
//                          Party Created at
//                        </Typography>
//                        <Typography variant="body1" component="span">
//                          {/* {new Date(
//                                 Number(party?.timestamps?.createdAt),
//                               ).toDateString()} */}
//                        </Typography>
//                      </Div>

//                      <Div>
//                        <Typography variant="body1" component="span">
//                          City
//                        </Typography>
//                        <Typography variant="body1" component="span">
//                          {/* {party?.city} */}
//                        </Typography>
//                      </Div>
//                    </Grid>
//                  </Div>

//                  {data?.getStockList?.map((value) => {
//                    return (
//                      <>
//                        <PrintViewReceiptForOneParty
//                          // sale={value}
//                          sale={undefined}
//                          type={"Detail"}
//                        />
//                        <Divider style={{ marginBottom: "30px" }} />
//                      </>
//                    );
//                  })}
//                </div>
//              );
//            })()}
//          </ComponentToPrint>
//        </div>
//      );
//    })();
//  }
